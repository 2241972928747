import {
  EMAIL_INBOX_URL,
  EMAIL_SEND_URL,
  EMAIL_OPTOUT_URL,
  EMAIL_SENTBOX_URL,
  EMAIL_FETCH_URL,
  NEWSLETTER_JOIN_URL,
  NEWSLETTER_SEND_URL,
  MAILBOX_VERIFY_URL,
  MS_VERIFY_URL,
  HTTP_SAVE_TEMPLATE_URL,
  EMAILS_GETTEMPLATES_URL,
} from 'app/constants/url';
import {customFetch} from 'app/utils/customFetch';

/**
 * Get email inbox.
 *
 * @export
 * @function
 * @name HTTP_EMAIL_INBOX
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_EMAIL_INBOX(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(EMAIL_INBOX_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Get email sentbox.
 *
 * @export
 * @function
 * @name HTTP_EMAIL_SENTBOX
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_EMAIL_SENTBOX(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(EMAIL_SENTBOX_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Fetch Email.
 *
 * @export
 * @function
 * @name HTTP_EMAIL_FETCH
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_EMAIL_FETCH(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(EMAIL_FETCH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Send Email.
 *
 * @export
 * @function
 * @name HTTP_EMAIL_SEND
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_EMAIL_SEND(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(EMAIL_SEND_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Opt out of Email.
 *
 * @export
 * @function
 * @name HTTP_EMAIL_OPTOUT
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_EMAIL_OPTOUT(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(EMAIL_OPTOUT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Send Newsletter.
 *
 * @export
 * @function
 * @name HTTP_NEWSLETTER_SEND
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_NEWSLETTER_SEND(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(NEWSLETTER_SEND_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Join Newsletter.
 *
 * @export
 * @function
 * @name HTTP_NEWSLETTER_JOIN
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_NEWSLETTER_JOIN(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(NEWSLETTER_JOIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Verify Mailbox.
 *
 * @export
 * @function
 * @name HTTP_MAILBOX_VERIFY
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_MAILBOX_VERIFY(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(MAILBOX_VERIFY_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Verify Mailbox.
 *
 * @export
 * @function
 * @name HTTP_MS_VERIFY
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_MS_VERIFY(payload) {
  const string = JSON.stringify(payload);
  return await customFetch('/api/ms/verify', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: string,
  });
}


/**
 * Send Email.
 *
 * @export
 * @function
 * @name HTTP_SAVE_TEMPLATE
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_SAVE_TEMPLATE(payload) {
  const string = JSON.stringify(payload);
  return await customFetch(HTTP_SAVE_TEMPLATE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: string,
  });
}

/**
 * Get templates.
 *
 * @export
 * @function
 * @name HTTP_REQUEST_TEMPLATES
 * @param payload
 * @returns {Promise<Response>}
 */
export async function HTTP_REQUEST_TEMPLATES(payload) {
  const url = `${EMAILS_GETTEMPLATES_URL}/:${payload}`;
  return await customFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
